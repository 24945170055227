/**=====================
 custom CSS Start
==========================**/
// you can add your custom css here

/* src/components/ImageBackground.css */

.image-background {
  position: relative;
  width: 100%;
  height: 100lvh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 90%;
  //padding-bottom: 5vh;
}

.custom-button {
  width: 380px;
  height: 60px;
  background-color: #8bbe1e;
  text-align: center;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 2px solid #8bbe1e; /* Keep the border green */
  transition: background-color 0.3s; /* Smooth hover transition */

  .custom-button:hover {
    background-color: #333; /* Dark background color on hover */
  }

  /* Hover effect */
  &:hover {
    background-color: #6f9c13; /* Change the background color on hover */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Add a subtle shadow on hover */
  }
}

/* Responsive styles for smaller screens (e.g., mobile) */
@media screen and (max-width: 768px) {
  .button-container {
    padding-bottom: 3vh;
  }

  .custom-button {
    width: 60%;
    height: 50px;
    font-size: 18px;
    border-radius: 20px;
  }
}

// TopNavBar.scss

// .topNavBar {
//   width: 100%;
//   height: 12%;
//   display: flex;
//   justify-content: center;
//   //align-items: center;
//   background-color: #91c71f;
// }

// .orgName {
//   color: #000;
//   font-family: Nunito;
//   font-size: 26px;
//   font-weight: 700;
//   line-height: 160%;
//   letter-spacing: 0.52px;
//   //padding-bottom: 1%;
//   padding-top: 1%;
//   justify-items: center;
//   align-items: center;
//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
//   @media (min-width: 769px) and (max-width: 1024px) {
//     font-size: 22px;
//   }
//   @media (min-width: 1025px) {
//     font-size: 26px;
//   }
// }

// .locationIcon {
//   //align-items: center;
//   color: white;
//   //margin-top: 5px;

//   svg {
//     margin-right: 10px;
//   }
//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
//   @media (min-width: 769px) and (max-width: 1024px) {
//     font-size: 22px;
//   }
//   @media (min-width: 1025px) {
//     font-size: 26px;
//   }
// }

// // Add mobile responsiveness
// @media (max-width: 768px) {
//   .topNavBar {
//     //height: auto;
//     flex-direction: column;
//   }
//   .orgName {
//     font-size: 18px;
//     //padding: 1% 0;
//   }
//   .locationIcon {
//     margin-top: 5px;
//   }
// }
